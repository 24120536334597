<template>
  <div class=container>
      <table class=table width="800" border="0" cellspacing="30" cellpadding="0">
		
					<tbody><tr>
						<td><div align="center"><font size="+2">Multispectral Image Database: Paints</font></div></td>
					</tr>
				<tr>
					<td>These zip files include full spectral resolution reflectance data from 400nm
to 700nm at 10nm steps (31 bands total).  Each band is stored as a 16-bit
grayscale PNG image. Image filenames are of the format 'object_ms_01.png',
where the '01' at the end signifies that this is the first image (captured at
400nm). Thus, '02' corresponds to 410nm, and so on, until '31' for 700nm.  The
color images below are displayed using sRGB values rendered under a neutral
daylight illuminant (D65).  Click on any thumbnail to open the full-size
image.</td>
				</tr>
				<tr><td><table border="0" width="800" align="center"><tbody><tr><td><table border="0" width="100%" align="center"><tbody><tr><td class="gallery"><center><a href="https://cave.cs.columbia.edu/old/databases/multispectral/images/oil_painting.png"><img src="https://cave.cs.columbia.edu/old/databases/multispectral/images/oil_painting_thumbnail.png" width="190"></a><br><a href="https://cave.cs.columbia.edu/old/databases/multispectral/zip/oil_painting_ms.zip">oil_painting_ms.zip</a></center></td><td class="gallery"><center><a href="https://cave.cs.columbia.edu/old/databases/multispectral/images/paints.png"><img src="https://cave.cs.columbia.edu/old/databases/multispectral/images/paints_thumbnail.png" width="190"></a><br><a href="https://cave.cs.columbia.edu/old/databases/multispectral/zip/paints_ms.zip">paints_ms.zip</a></center></td><td class="gallery"><center><a href="https://cave.cs.columbia.edu/old/databases/multispectral/images/watercolors.png"><img src="https://cave.cs.columbia.edu/old/databases/multispectral/images/watercolors_thumbnail.png" width="190"></a><br><a href="https://cave.cs.columbia.edu/old/databases/multispectral/zip/watercolors_ms.zip">watercolors_ms.zip</a></center></td></tr></tbody></table></td></tr></tbody></table></td></tr><tr><td><hr></td></tr>
			<tr><td height="20"><a href="/repository/Multispectral">Back to project page</a></td></tr>
			</tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
    margin:auto
}
</style>